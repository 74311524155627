import * as React from "react"
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import ollogo from "../images/OLlogo.svg"

const query = graphql`
  query {
    home: file(relativePath: { eq: "home-plot-200.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const IndexPage = () => {

  const data = useStaticQuery(query);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Signallers Croft - Live the difference</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/images/favicon/site.webmanifest"></link>
      </Helmet>

      <main className="h-screen flex flex-col">
        <div className="lines flex">
          <div className="linestart"></div>
          <div className="linerepeat flex-1"></div>
        </div>

        <div className="flex flex-wrap flex-1">
          <div className="flex-1 flex flex-col items-center justify-center bg-green relative pt-20">
            <div className="mx-6 sm:-mt-28">
              <header className="mb-16">
                <div className="flex justify-center logo"></div>
                <div className="hidden">
                  <h1>Signaller's Croft</h1>
                  <span>Live the difference</span>
                </div>
              </header>

              <p className="intro text-center text-xl">
                Discover a stunning new destination, featuring contemporary 2, 3 & 4-bedroom homes.
              </p>

              <a
                href="https://www.onward-living.co.uk/register-interest/?contact-development=signallers-croft"
                className="register-interest text-center rounded-full block mb-20"
              >
                Register your interest
              </a>
            </div>
          </div>

          <div className="flex-none w-screen sm:w-auto sm:flex-1 sm:order-first">
            <Img
              fluid={data.home.childImageSharp.fluid}
              style={{ height: "100%", maxHeight: "100vh" }}
            />
          </div>

          <div className="flex-1 sm:flex-none sm:absolute sm:bottom-0 sm:left-1/2 sm:right-0 bg-dark-green">
            <footer className="flex justify-center flex-wrap py-4">
              <span className="block w-full text-center mb-6">Created by</span>
              <img alt="Onward Living" src={ollogo} />
            </footer>
          </div>
        </div>
      </main>
    </>
  );
}

export default IndexPage
